import React, { useState, useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { theme } from "../styles"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "src/components/seo"
import Gpg from "src/components/Gpg"
import Measure from "react-measure"
import { GlobalStyle } from "../styles"
import Layout from "src/components/Layout"
import config from "../data/config.yaml"

const PaymentPage = props => {
  useStaticQuery(graphql`
    query GpgQuery {
      site {
        siteMetadata {
          currencyOptions {
            key
            value
            address
            text
          }
        }
      }
    }
  `)
  const [size, setSize] = useState()
  const [isReady, setIsReady] = useState(false)
  const [dimensions, setDimensions] = useState()

  useEffect((prevProp, prevState) => {
    let fontSize = parseInt(
      window.getComputedStyle(document.body).fontSize || 14
    )
    let size = fontSize * 30
    setSize(size)
  }, [])

  useEffect(
    (prevProp, prevState) => {
      let fontSize = parseInt(
        window.getComputedStyle(document.body).fontSize || 14
      )
      let size = fontSize * 30
      setSize(size)
      setIsReady(true)
    },
    [dimensions]
  )
  return (
    <ThemeProvider theme={theme}>
      <Measure
        bounds
        onResize={contentRect => {
          setDimensions({ dimensions: contentRect.bounds })
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <Layout theme={theme} withBreadCrumb activePage="GPG">
              <GlobalStyle bodyMarginTop="0vw" />
              <SEO title="GPG Key" keywords={["gpg", "pgp", "key"]} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  backgroundColor: "#000",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  {isReady && <Gpg theme={theme} size={size} pgpPublicKey={config.pgpPublicKey}></Gpg>}
                </div>
              </div>
            </Layout>
          </div>
        )}
      </Measure>
    </ThemeProvider>
  )
}

export default PaymentPage
