import styled from "styled-components"

const StyledGpg = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.1rem;
  align-items: center;

  padding: 0rem 2rem;
  pre {
    background-color: ${({theme}) => theme.bgColor} !important;
    color: ${({theme}) => theme.mainColor} !important;
    border: 0.2rem solid ${({theme}) => theme.mainColor} !important;
    padding: 2rem;
    text-align: left;
    overflow: auto;
  }

  @media screen and (max-width: 361px) and (min-width: 1px) {
    padding: 0rem 1rem;
    width: 100% !important;
    pre {
      font-size: 0.9rem !important;
      padding: 1rem;
    }
  }
  @media screen and (max-width: 400px) and (min-width: 361px) {
    padding: 0rem 1rem;
    width: 100% !important;
    pre {
      font-size: 0.95rem !important;
      padding: 1rem;
    }
  }
  @media screen and (max-width: 500px) and (min-width: 400px) {
    padding: 0rem 1rem;
    width: 100% !important;
    pre {
      font-size: 1rem !important;
      padding: 1rem;
    }
  }
  @media screen and (max-width: 680px) and (min-width: 500px) {
    padding: 0rem 1rem;
    width: 100% !important;
    pre {
      font-size: 1.1rem !important;
      padding: 1rem;
    }
  }
  @media screen and (max-width: 800px) and (min-width: 680px) {
    padding: 0rem 1rem;
    width: 100% !important;
    pre {
      font-size: 1.2rem !important;
      padding: 1rem;
    }
  }

  @media screen and (min-width: 825px) {
    padding: 0rem 1rem;
    width: ${({size}) => size * 2}px !important;
    pre {
      font-size: 1.3rem !important;
      padding: 2rem;
    }
  }
  .ui.basic.inverted.button,
  .ui.basic.inverted.buttons .button {
    font-family: ${({ theme }) => theme.subTitleFont}!important;
    font-size: 1.2rem;
    box-shadow: 0 0 0 0.1rem ${({theme}) => theme.mainColor} inset !important;
    background-color: ${({theme}) => theme.bgColor} !important;
    color: ${({theme}) => theme.mainColor} !important;
    border: 0.05rem solid ${({theme}) => theme.mainColor} !important;
    width: ${({size}) => size / 2}px !important;
  }

  .ui.basic.inverted.button:focus,
  .ui.basic.inverted.button:hover {
    box-shadow: 0 0 0 0.14rem ${({theme}) => theme.mainColor} inset !important;
    background-color: ${({theme}) => theme.bgColor} !important;
    color: ${({theme}) => theme.mainColor} !important;
    border: 0.06rem solid ${({theme}) => theme.mainColor} !important;
  }
  .ui.basic.inverted.button:active {
    box-shadow: 0 0 0 0.16rem ${({theme}) => theme.mainColor} inset !important;
    border: 0.09rem solid ${({theme}) => theme.mainColor} !important;
  }
`
export default StyledGpg
