import React, { useRef, useEffect, useState } from "react"
import  Button  from "src/components/Button"
import StyledGpg from "./styles"
import { CopyToClipboard } from "react-copy-to-clipboard"

const Gpg = ({ theme = { mainColor: "#fff" }, size, pgpPublicKey }) => {
  const inputRef = useRef(null)
  const [copyButtonContent, setCopyButtonContent] = useState("copy key")
  const [key, setKey] = useState(null)
  const [copyButtonContentCopied, setCopyButtonContentCopied] = useState(false)
  useEffect(() => {

    if(!key){
      setKey(inputRef.current.innerText);
    }


    setTimeout(() => {
      if (copyButtonContentCopied) {
        setCopyButtonContent("copy key")
        setCopyButtonContentCopied(false)
      }
    }, 500)
  }, [copyButtonContentCopied])

  return (
    <StyledGpg size={size} theme={theme} width={"50%"}>
      <pre ref={inputRef}>
        {String.raw`${pgpPublicKey}`}
      </pre>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
          backgroundColor: "#000",
          width: "100%"
        }}
      >
        <CopyToClipboard
          text={key}
          onCopy={() => {
            setCopyButtonContent("copied!")
            setCopyButtonContentCopied(true)
          }}
        >
          <Button
            basic={true}
            content={copyButtonContent}
            inverted={true}
            size="small"
            width = {size}
            fluid
            onClick={e => {

              //navigator.clipboard.writeText(inputRef.current.innerText)

            }}
          ></Button>
        </CopyToClipboard>

        <a
          className="ui small basic fluid icon inverted right labeled button"
          download="gpg.txt"
          href="/gpg.txt"
        >
          download
        </a>
      </div>
    </StyledGpg>
  )
}

export default Gpg
